(function ($) {
    //Menu

    $('button.js-menu').click(function() {
      var $elements = {
        'form': $('body'),
      };
  
      // $('.js-search-input').focus();
      $('body').toggleClass('menu-opened');
      // $('.search-form').toggleClass('opened');
    });
  
    //Search
    $('.js-btn-search').click(function() {
      var $elements = {
        'form': $('body'),
      };
  
      // $('.js-search-input').focus();
      $('body').toggleClass('search-opened');
      // $('.search-form').toggleClass('opened');
    });
  
  //Btn Quicj Facts
  $(document).on('click', '.btn--facts', function () {
    var parent = $(this).closest('.cl__wrap');
    // $('.js-search-input').focus();

    parent.toggleClass('facts-opened');
    // $('.qf__content').toggleClass('d-block');
    // $('.search-form').toggleClass('opened');
  });

  //Stickys
  var qf_sticky = new Sticky('.js-qf-sticky');

  //Simplebar
  $('.js-scrollbar').each(function (i, e) {
    new SimpleBar($(e)[0]);
  });

  //Btn Expand
  $('.js-text-expand').click(function () {
    var windowWidth = $(window).width();
    var parent = $(this).prevAll('[data-limit_desktop], [data-limit_mobile]');
    var desktop = parent.data('limit_desktop');
    var mobile = parent.data('limit_mobile');
    $(this).toggleClass('active');
    $(parent).toggleClass('active');
    
    if (!parent.hasClass('active')) {
      if (windowWidth > 767 && desktop) {
        parent.height(desktop);
      } else if (windowWidth <= 767 && mobile) {
        parent.height(mobile);
      } else {
        parent.removeAttr('style');
      }
    } else {
      parent.removeAttr('style');
    }
  });

  //Btn More
  $('.js-more-options').click(function () {
    var parent = $(this).closest('.more-cont');
    var hidden = parent.find('.d-none');
    hidden.removeClass('d-none');
    $(this).addClass('d-none');
  });
  

  window.anchorSmoothScroll = function (selector, offset_top) {

    $(selector + '[href^="#"]:not([href="#"])').click(function (e) {
      var target = $(this.hash);
      offset_top = ( $(this).data('offset-top') ) ? $(this).data('offset-top') : ( offset_top ) ? offset_top : 0;

      if ( target.length ) {
        $('html, body').animate({
          scrollTop: target.offset().top - offset_top
        }, 600);

        e.preventDefault();
      }
    });
  };

  // Run Functions
  anchorSmoothScroll('.move', 20);

})(jQuery);